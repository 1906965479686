import "./styles/DocumentScreen.css";
import backspaceArrow from "./../../../assets/BackspaceArrow.svg";
import { fetchDocumentsService } from "../../../services/lessons/fetchDocumentsService";
import { usePopupContext } from "../../../hooks/UsePopupContext";
import QuizPopup from "../lessonsScreen/components/QuizPopup";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

export default function DocumentScreen() {
	const { popup } = usePopupContext();
	const { dispatch3 } = usePopupContext();
	const [documentVideoID, setdocumentVideoID] = useState(undefined);
	const [documentContent, setdocumentContent] = useState("");
	const [isLoadDocument, setLoadDocument] = useState(false);
	useEffect(function () {
		loadingEvents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	async function loadingEvents() {
		setLoadDocument(true);
		setdocumentVideoID(Cookies.get("documentVideoID"));
		const reponses = await fetchDocumentsService(
			Cookies.get("documentVideoID")
		);
		setdocumentContent(reponses);
		setLoadDocument(false);
	}
	return (
		<div className='HomeContextContainer'>
			<div className='GlobalEvaluationWrapper'>
				<div className='GlobalEvaluationButtonHeadWrapper'>
					<div className='GlobalEvaluationHeading'>
						<Link to='/home/lessons'>
							<img src={backspaceArrow} alt='backspaceArrow' />
						</Link>
						<h2>Document Name</h2>
					</div>
				</div>
			</div>
			{popup === null || popup === undefined ? null : (
				<div className='LessonPlayerPopupWrapper'>
					<QuizPopup
						videoID={popup}
						isDocument={isLoadDocument ? false : true}
						courseId={Cookies.get("documentCourseId")}
						subCoursesId={Cookies.get("documentSubCoursesId")}
					/>
				</div>
			)}
			<div className='DocumentScreenParser'>
				{isLoadDocument
					? "Please wait while processing your document"
					: parse(documentContent)}
			</div>
			{isLoadDocument ? null : (
				<button
					className='DocumentScreenButton'
					onClick={function () {
						dispatch3({
							type: "POPUPTRUE",
							payload: documentVideoID,
						});
					}}>
					Play Quiz
				</button>
			)}
		</div>
	);
}
